/*COMMON*/

html {
  font-size: 0.9rem;
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

* {
    outline: none;
}

@include media-breakpoint-up(lg) {
    .col-lg-25 {
        flex: 0 0 20.0%
    }

    html {
        font-size: 1.08rem;
    }
}

body {
    font-family: 'Nunito', sans-serif;
    font-size: 0.9rem;
}


@include media-breakpoint-up(lg) {
    .navbar {
        @include media-breakpoint-down(md) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.navbar-toggler {
    @include media-breakpoint-down(md) {
        margin-right: $spacer;
        margin-bottom: 9.6px;
    }
}

.navbar-icon {
    height: 40px;
    width: 50px;
    padding: 5px 0;
    margin-left: $spacer/2;
    line-height: 30px;
    text-align: center;
    color: $primary;
    border:1px solid #dcdcdc;
    font-size: 20px;
}

.navbar-brand img {
    @include transition();

    @include media-breakpoint-down(md) {
        height: 40px;
        padding-left: $spacer;
    }

    @include media-breakpoint-up(md) {
        height: 55px
    }
}

.nav-link {
    @include media-breakpoint-down(md) {
        border-bottom: 1px solid #dcdcdc;
        padding-left: 15px !important;
        padding-right: 15px !important;
        color: #fff !important;
    }

    @include media-breakpoint-only(lg) {
        padding-left: 21px !important;
        padding-right: 21px !important;
    }

    @include media-breakpoint-only(xl) {
        padding-left: 21px !important;
        padding-right: 21px !important;
    }
}



#menu-main_menu {
    @include media-breakpoint-down(lg) {
        font-size: 1.1rem;
        background: $primary;
        margin-top: $spacer
    }
}

//

div.cookie_notice[role="status"] {
    display: none;
    position: fixed;
    left:0px;
    bottom: 0px;
    z-index:100000;
    background: #fff none repeat scroll 0 0;
    font-size: 11px;
}

div[role="status"] {
    box-sizing: border-box;
    color: #333;
    padding: 10px 32px;
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 16px;
}
div[role="status"] a {
    color: #333;
    font-size: 11px;
    font-family: arial;
}

div[role="status"] span {
    font-weight: bold;
    text-decoration: underline;
}

div[role="status"] span:hover {
    color: #AA0331;
    cursor: pointer;
    text-decoration: none;
}

div[role="status"] .closer .close_eu {
    font-weight: bold;
    cursor: pointer;
    position: relative;
    margin-top: 5px;
    text-decoration: none;
    width: 100px;
    opacity: 1.0;
    text-shadow: none;
    font-size: 14px;
    color:#333;
    line-height: 1;
    font-size: 11px;
}

#gfcookielaw h4 {
    margin-top: 10px
}

//FOOTER

.partner-squared {
    max-height: 40px;
    @include media-breakpoint-up(md) {
        max-height: 100px
    }
}

.partner-thin {
    max-height: 30px;
    @include media-breakpoint-up(md) {
        max-height: 60px
    }
}

@media screen and (max-width: 911px) {
    div[role="status"] .closer .close_eu {
        margin-top: 15px;
    }
}

/*HOME*/
#home-slider {

    .item {
        overflow: hidden;
        position: relative;
        height: 50vh;

        @include media-breakpoint-up(lg) {
            height: 100vh;
        }
    }

    .owl-dots {
        position: absolute;
        right: 25px;
        bottom: 25px;

        .owl-dot span {
            width: 20px;
            height: 20px;
        }

    }

    .owl-caption {
        position: absolute;
        z-index: 30;
        left: 0px;
        top: 0px;
        bottom: 0px;
        width: 50%;
        text-shadow: 1px 1px 1px #333;
    }

    .gear {
        position: absolute;
        z-index: 320;
        left: -10vh;
        bottom: -10vh;
        width: 20vh;
        height: 20vh;
        background-image: url( '../images/theme/gear.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;

        @include media-breakpoint-up(lg) {
            left: -30vh;
            bottom: -30vh;
            width: 60vh;
            height: 60vh;
        }
    }

    .curtain {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        background: rgba(255,162,0,0.5);
        z-index: 10;
        width: var(--width);
        will-change: opacity;
    }
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: $primary;
}

.home-product {
    position: relative;
    display: block;
    overflow: hidden;

    .home-product-image {
        @include transition();
    }

    .home-product-title {
        position: absolute;
        z-index: 20;
        left: 15px;
        bottom: 15px;
        margin: 0px;
        color: white;
        text-shadow: 1px 1px 1px #333;
        @include transition();
        border-bottom: 1px solid transparent;
    }

    .home-product-overlay {
        position: absolute;
        z-index: 10;
        left: -1000px;
        bottom: -1000px;
        width: 100%;
        height: 100%;
        -ms-transition: all 0.2s ease;-webkit-transition: all 0.2s ease;-o-transition: all 0.2s ease;-moz-transition: all 0.2s ease;transition: all 0.2s ease;
        opacity: 0.6;
    }

    &:hover {

        .home-product-image {
            transform: scale(1.1);
        }

        .home-product-title {
            bottom: 45%;
            border-bottom-color: $primary;
        }

        .home-product-overlay {
            left: 0px;
            bottom: 0px;
        }
    }
}

/*PRODOTTI*/

.header-prodotto:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 60%;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 );
}

.titolo-blocco {
    position: realtive;
    width: 80%;
    @include media-breakpoint-up(lg) {
        display: block;
        width: 45%;
    }

    &:after {
        content: '';
        display: block;
        height: 3px;
        background-color: $primary;
        width: 40%;
        z-index: 10;
    }
}

.owl-centered .owl-stage {
    padding-left: 0px !important
}

/*SERVIZI*/

.servizi-icona {
    height: 45px;
    @include media-breakpoint-up(lg) {
        height: 90px;
    }
}

/*CONTATTI*/
.acf-map {
    height: 200px;
    @include media-breakpoint-up(lg) {
        height: 300px;
    }
}

.grecaptcha-badge {
    display: none;
}


