/*!
Theme Name:         Temabase
Version:            3.0.0
Author:             Andrea Testa
*/

@import "resources/sass/vendor/bootstrap/variables";
@import "~bootstrap/scss/bootstrap";

@import '~@fortawesome/fontawesome-pro/css/all.css';

@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";

@import "resources/sass/custom/app.scss";
